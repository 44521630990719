export default {
  name: 'IntegrationSteps',
  data: function data() {
    return {
      active: 0
    };
  },
  methods: {
    next: function next() {
      // eslint-disable-next-line no-plusplus
      if (this.active++ > 2) this.active = 0;
    }
  }
};